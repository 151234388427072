import React from "react";

import type {
  IconDefinition,
  SizeProp,
} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";

import { customButtonStyles } from "./MapStylesForDraw";

interface IMapButtonProps {
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
  icon: IconDefinition;
  top: number;
  styling?: { [key: string]: string | number };
  iconSize?: SizeProp;
  disabled?: boolean;
}

export const MapButton = (props: IMapButtonProps) => {
  const { onClick, icon, top, styling, iconSize, disabled } = props;
  return (
    <Button
      disabled={disabled}
      sx={{
        ...customButtonStyles,
        position: "absolute",
        right: 7,
        top,
        zIndex: 0,
        border: "2px solid #ddd",
        ...styling,
      }}
      onClick={() => onClick((prev) => !prev)}
    >
      <FontAwesomeIcon icon={icon} size={iconSize ?? "lg"} color="black" />
    </Button>
  );
};
